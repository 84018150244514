import store from "./Store";

function getMeta(metas, key, defaultValue = '') {
    if (metas.length === 0) {
        return defaultValue;
    }
    const value = metas
        .filter((meta) => { return meta.key === key })
        .map((meta) => {return meta.value})
        .join();
    return value !== '' ? value: defaultValue;
}

const generateUrl = (entry = '', params = '') => {
    let url = store.get('frontend_url');

    if (entry === '') {
        entry = store.get('frontend_entrypoint');
    } else if (!entry.startsWith("/")) {
        url += "/";
    }

    url += entry

    url += `?collector_token=${store.get('collector_token')}&platform=${store.get("platform")}`;

    let default_params = store.get('default_params') || [];
    default_params.forEach(p => {
        url += `&${p[0]}=${p[1]}`;
    });

    if (params !== '') {
        url += `&${params}`;
    }

    return url
}

export { getMeta, generateUrl };
