import TeeLogger from './Logger.js'
import store from './Store'
import {generateUrl} from "./Helper";

function Elements () {
    this.elements = [];
    this.mapping = {
        'tee-open-btn': {type: 'event', listener: 'click', event: 'open-tee'},
        'tee-widget': {type: 'widget', listener: 'click', event: 'open-tee'},
    };

    this.init = () => {
        this.initElements()

        window.addEventListener('newPage', this.initElements);
    };

    this.initElements = () => {
        TeeLogger.log('searching for Tee Elements...')
        let foundElements = document.getElementsByClassName('tee-element');
        foundElements = Array.prototype.slice.call(foundElements, 0);
        TeeLogger.log(`found ${foundElements.length} elements`);
        foundElements.forEach(element => {
            this.register(element);
        });
    }

    this.register = (element) => {
        Array.from(element.classList).forEach(elClass => {
            const handler = this.mapping[elClass];
            if (handler === undefined) {
                return;
            }

            switch (handler.type) {
                case 'event':
                    this.registerEvent(element, handler)
                    break;
                case 'widget':
                    this.registerWidget(element, handler)
                    break;
            }

            TeeLogger.log('registered Element', element);
        });
    };

    this.registerEvent = (element, handler) => {
        element.dataset.teeElementHandler = JSON.stringify(handler);
        let event = this.handlers[handler.listener]
        element.addEventListener(handler.listener, event)
        element.style.display = 'block';
        TeeLogger.log('registered Event', element);
    }

    this.registerWidget = (element, handler) => {
        if (element.children.length > 0) {
            return;
        }

        const widget = document.createElement('iframe');
        widget.src = generateUrl(`/widget/${element.dataset.widget}`)

        const meWrapper = document.createElement('div');
        meWrapper.classList.add('tee-el', 'no-currency');

        element.appendChild(widget)
        element.appendChild(meWrapper)

        if (element.dataset.open) {
            this.addClickHandler(element, handler)
        }

        this.addPointsListener(widget.contentWindow)

        TeeFrontend.me.initTeeFromWidget()

        TeeLogger.log('registered Event', element);
    }

    this.handlers = {
        click: (element) => {
            let o = element.currentTarget;
            let eventData = JSON.parse(o.dataset.teeElementHandler)
            TeeLogger.log('click', eventData)
            const link = o.dataset.open;
            if (!link) { return; }
            switch(eventData.event) {
                case 'open-tee':
                    TeeFrontend.me.openTee(undefined, link);
                    break;
            }
        }

    };

    this.addClickHandler = (element, handler) => {
        const clickHandler = document.createElement('div');
        clickHandler.classList.add('click-handler');
        clickHandler.dataset.open = element.dataset.open

        element.appendChild(clickHandler)

        this.registerEvent(clickHandler, handler)
    }

    this.addPointsListener = (element) => {
        store.observe('me', () => {
            element.postMessage('points_changed', '*')
        });
    }
}

let TeeElements = new Elements();
export default TeeElements;
