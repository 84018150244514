import Settings from './Settings.js';

function Logger() {
    this.log = function (data, args = null) {
        if (this.isDebug()) {
            args = [];
            args.push( '[TEE] ' );
            // Note: arguments is part of the prototype
            for( var i = 0; i < arguments.length; i++ ) {
                args.push( arguments[i] );
            }
            console.log.apply( console, args );
        }
    };
    this.msg = function (data, args = null) {
        if (this.isDebug()) {
            args = [];
            args.push( '[TEE Connect] ' );
            // Note: arguments is part of the prototype
            for( var i = 0; i < arguments.length; i++ ) {
                args.push( arguments[i] );
            }
            console.log.apply( console, args );
        }
    };
    this.error = function (data, args = null) {
        if (this.isDebug()) {
            args = [];
            args.push( '[TEE] ' );
            // Note: arguments is part of the prototype
            for( var i = 0; i < arguments.length; i++ ) {
                args.push( arguments[i] );
            }
            console.error.apply( console, args );
        }
    };

    this.isDebug = function () {
        return Settings.debug || localStorage.getItem('tee-debug') === 'true';
    };
};

let TeeLogger = new Logger();
export default TeeLogger;
