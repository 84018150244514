import store from './Store.js';
import Settings from './Settings.js';
import TeeLogger from './Logger.js';

import { Me } from './Me.js';

function MeHandler (TeeConnect) {
    this.meEls = [];
    this.mes = [];
    this.teePopup = null;
    this.blockPopup = false;

    this.init = function() {
        this.meEls = [];

        if (store.get('platform') === 'mobileweb') {
          var mecol = document.getElementsByClassName('tee-el');
          this.meEls.push(Array.prototype.slice.call(mecol, 0)[0]);
        } else {
          this.meEls.push(document.getElementById('tee-main'));
        }

        window.addEventListener('tee', this.handleEvents)
        window.addEventListener('tee_hide_popup', this.handleHidePopup)

        TeeLogger.log('Initialized Me Handler');

        this.meEls.forEach(element => {
            this.mountMe(element)
        });
    };

    this.mountMe = (element) => {
        if (!element) {
            return
        }
        let invisible = false;

        if (element.classList.contains('no-currency')) {
            invisible = true
        }

        const me = new Me(element, TeeConnect);
        this.mes.push(me);
        me.mount(invisible);
    }

    this.openTee = (paras = "", entryOverride = "") => {
        this.mes.forEach(me => {
            me.openTEE(paras, entryOverride);
        })
    };
    this.closeTEE = () => {
        this.mes.forEach(me => {
            me.closeTEE();
        });
    };
    this.removeTeeIcon = () => {
        this.mes.forEach(me => {
            me.removeTeeIcon();
        });
    };
    this.handleEvents = (e) => {
        const event = e.detail;
        if (event.target === 'me') {
            TeeLogger.log('TeeMeEvent', event)
            switch(event.event) {
                case 'loaded':
                    let entryOverride = store.get('entryOverride');
                    if (entryOverride) {
                        let optional_params = store.get('optionalParameter') || [];
                        let url = '';
                        optional_params.forEach((p, i) => {
                            if (i > 0) {
                                url = `${url}&${p.param}=${p.value}`
                            } else {
                                url = `${url}${p.param}=${p.value}`
                            }
                        });

                        event.me.scrollTo()

                        event.me.openTEE(url, entryOverride);

                        this.cleanupDeeplink()
                    }
                    break;
            }
        } else if (event.target === 'popup') {
            TeeLogger.log('TeePopupEvent', event)
            switch(event.event) {
                case 'block':
                    this.blockPopup = event.block || false;
                    if (this.blockPopup && this.teePopup !== null) {
                        this.teePopup.style.display = 'none';
                    }
                    break;
            }
        }
    };
    this.handleHidePopup = () => { //workaround until nnp sends adjusted event
        this.blockPopup = true;
        if (this.teePopup !== null) {
            this.teePopup.style.display = 'none';
        }
    };
    this.showPopUp = (message) => {
        if (this.teePopup == null) {
            //Make the iframe popup layout if not yet done
            this.teePopup = document.createElement('div');
            this.popupframe = document.createElement('iframe');
            this.popupframe.className = message.data.class;

            this.teePopup.appendChild(this.popupframe);
            this.teePopup.id = "tee-popup";
            this.teePopup.style.display = "none";
            document.body.appendChild(this.teePopup);

            if (message.data.class === 'tee-large' && window.innerHeight < 620) {
                this.teePopup.style.overflowY = 'scroll';
                this.teePopup.style.overflowX = 'hidden';
            }

            this.popupframe.onload = this.popupLoaded;
            this.teePopup.addEventListener('click', function(e) {
                e.target.style.display = "none";
            });

            window.addEventListener('message', this.popupClose, false);
        }

        if (!this.blockPopup) {
            this.popupframe.src = message.data.notify_url + '&platform=' + store.get('platform') + '&origin=' + window.location.origin;
        }
    };
    this.popupLoaded = (e) => {
        this.teePopup.style.display = "block";
    };
    this.popupClose = (e) => {
        if (e == null) { return; }
        //Check if the Origin of the Close Event is tee
        if (e.origin != store.get('frontend_url')) { return; }

        //Check if Tee Popup is defined
        if (TeeFrontend.me.teePopup === null) { return; }

        let data = e.data;
        let link = '';

        // If data is not a string, return
        if (data.constructor.name !== 'String') {
          return;
        }

        if (data.includes('$')) {
            data = data.split('$');
            link = data[1];
            data = data[0];
        }

        switch(data) {
            case "tee_closePopup":
                TeeFrontend.me.teePopup.style.display = "none";
                break;
            case "tee_closePopup_openTee":
                TeeFrontend.me.teePopup.style.display = "none";
                TeeFrontend.me.openTee();
                break;
            case "deeplink":
                TeeFrontend.me.teePopup.style.display = "none";
                TeeFrontend.me.openTee("", link);
                break;
            case "open-link":
                TeeFrontend.me.teePopup.style.display = "none";
                window.location = link;
                break;
            case "open-link-blank":
                TeeFrontend.me.teePopup.style.display = "none";
                window.open(link, '_blank');
                break;
        }
        if (String(data).startsWith("tee_openOverview")) {
            var paras = String(data).split("?");
            TeeFrontend.me.openTee(paras[1]);
            TeeFrontend.me.teePopup.style.display = "none";
        }
    };

    this.initTeeFromWidget = () => {
        if (this.mes.length > 0) {
            return
        }

        this.init()
    }

    this.cleanupDeeplink = () => {
        const wholeUrl = new URL(window.location.href)
        let param = new URLSearchParams(wholeUrl.search.slice(1))
        param.delete('tee')
        if (param.toString() !== '') {
            param = `?${param}`
        }
        window.history.replaceState(
            {},
            '',
            `${window.location.pathname}${param}${window.location.hash}`,
        )
    }
}

export { MeHandler };
