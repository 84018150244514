import TeeLogger from './Logger.js';

function UpdatePoints (store) {
    this.pointsLocked = false;
    this.pointsLockedTimeout = 0;
    this.pointsQueue = [];

    this.setPoints = (points) => {
        if (this.pointsLocked) {
            TeeLogger.log('Points locked, queueing points update')

            this.pointsQueue.push(points);
            return;
        }

        store.set('me', { ...store.get('me'), ...{ points_available: points } });
    }

    this.lockPoints = () => {
        TeeLogger.log('Points lock started')
        this.pointsLocked = true;

        this.pointsLockedTimeout = window.setTimeout(() => {
            this.unlockPoints();
        }, 10000)
    }

    this.unlockPoints = () => {
        TeeLogger.log('Points lock stopped')
        this.pointsLocked = false;
        window.clearTimeout(this.pointsLockedTimeout);

        while (this.pointsQueue.length > 0) {
            this.setPoints(this.pointsQueue.shift());
        }
    }
}

export default UpdatePoints;