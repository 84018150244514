import Settings from './Settings.js';
import TeeLogger from './Logger.js';
import ReconnectingWebSocket from 'reconnecting-websocket';

function Connect () {
    let client = new ReconnectingWebSocket(Settings.ws.service, 'echo-protocol', {
        debug: false,
        reconnectInterval: 3000,
        maxReconnectAttempts: 2
    });
    let receivers = [];
    client.onerror = () => {
        TeeLogger.log('Connecting error');
    }

    client.onopen = () => {
        TeeLogger.log('Connected to TEE');
        this.init();
    }

    client.onclose = () => {
        TeeLogger.log('Connection closing... reconnecting in 3 seconds');

        window.dispatchEvent(new CustomEvent('tee_request_login'));

        this.closing()
    }

    client.onmessage = (message) => {
        if (message.data && typeof message.data === 'string') {
            const o = JSON.parse(message.data);
            receivers.forEach(r => r(o));
        }
    }

    this.client = client;

    this.addMessageReceiver = (receiver) => {
        if(!receivers.includes(receiver)) {
            receivers.push(receiver);
        }
    }

    this.removeMessageReceiver = (receiver) => {
        const receiverIndex = receivers.findIndex(r => r === receiver);
        receivers.splice(receiverIndex, 1);
    }

    this.send = (message) => {
        if (typeof message === 'object') {
            message = JSON.stringify(message);
        }
        client.send(message);
    }

    this.kill = () => {
        client.close();
    }

    this.reconnnect = () => {
        client.reconnect();
    }

    this.init = () => {
        TeeLogger.log('No Init Function defined');
    };

    this.closing = () => {
        TeeLogger.log('No closing function defined');
    }
}
let TeeConnect = new Connect();
export default TeeConnect;
