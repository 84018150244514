import polyfill from './polyfill.js'
import store from './Store.js';
import Settings from './Settings.js';
import TeeLogger from './Logger.js';
import TeeConnect from './Connect.js';
import TeeElements from './Elements.js';
import UpdatePoints from './UpdatePoints.js';
import { MeHandler } from './MeHandler.js';
import { Tracker } from './Tracker.js';
import { getMeta } from "./Helper.js";

let rmtkey = '_rm_tee';
let allowedParams = ['vtoken'];

function TEE () {
    this.me = new MeHandler(TeeConnect);
    this.points = new UpdatePoints(store);
    let TeeTracker = this.TeeTracker = new Tracker(TeeConnect);

    this.mount = function() {
        if (Array.isArray(Settings.css)) {
            Settings.css.forEach((src) => this.addStylesheet(src));
        }

        TeeLogger.log('Preparing TEE');

        if (window[rmtkey]) {
            this.queue = window[rmtkey].q;
            this.queue.forEach((e) => this.handle(e));
            window[rmtkey] = this.handle;
        };
    };
    this.destroy = function(){
      this.me.removeTeeIcon();
      this.TeeTracker = {};
      TeeConnect.kill();
    }
    this.handle = function() {
        let o = typeof arguments[0] === 'object' ? arguments[0] : arguments;
        let valid = ['get', 'set', 'init'];
        let args = [...o];
        let method = args.shift();

        if (!valid.includes(method)) {
            TeeLogger.error(`Unknonwn handle command: ${method}`);
        }
        this[method].apply(this, args);
    };
    this.addStylesheet = function(src) {
        let link = document.createElement('link');
        link.href = src;
        link.type = 'text/css';
        link.rel = 'stylesheet';
        document.getElementsByTagName('head')[0].appendChild(link);
    };
    this.set = function (key, val) {
        if (key === 'debug') {
            Settings.debug = val;
            TeeLogger.log('Logging enabled');
        }
        if (key === 'default_step_event_target') {
            Settings.defaultStepEventTarget = val;
        }
        if (key === 'api_token') {
            store.set('api_token', val);
        }
        if (key === 'remote_id') {
            store.set('remote_id', val);
        }
        if (key === 'email') {
            store.set('email', val);
        }
        if (key === 'sso_logged_in') {
            store.set('sso_logged_in', val);
        }
        if (key === 'update_id') {
            store.set('update_id', val);
        }
        if (key === 'username') {
            store.set('username', val);
        }
        if (key === 'default_params') {
            store.set('default_params', val);
        }

        if (key === 'bodyLockDisabled') {
            store.set('bodyLockDisabled', val)
        }
    };
    this.get = function (key) {
        return store.get(key);
    };
    this.init = function () {
        if (store.get('initialized')) {
            TeeConnect.reconnnect();
            TeeLogger.log('Killing the old Connection');
        }
        TeeLogger.log('TEE mounted');
        TeeLogger.log('Connection to TEE...');

        TeeConnect.addMessageReceiver(this.messageReceiver);
        TeeConnect.init = this.connect;
        TeeConnect.closing = this.closing;

        TeeLogger.log('ready State', TeeConnect.client.readyState);

        if (TeeConnect.client.readyState === 1) {
            this.connect();
        }
    };
    /**
     * Explictly logout by clearing the store.
     */
    this.logout = function() {
      store.remove('collector_token');
      store.remove('remote_id');
    };
    /**
     * Remount TEE me elements and widgets.
     */
    this.remount = function() {
      this.me.init();
      TeeElements.init();
    };
    this.connect = function() {
        let teeClientPlatform =  'desktop';
        if (window.matchMedia("(max-width: 767px)").matches) {
          teeClientPlatform = 'mobileweb'
        }

        let tee_client = `tee/${teeClientPlatform} (${Settings.version})`;

        let collector_token = store.get('collector_token');
        let last_collector_token = localStorage.getItem('last_collector_token');
        let last_sso_logged_in = localStorage.getItem('last_sso_logged_in');

        if (store.get('sso_logged_in')) {
            localStorage.setItem('last_sso_logged_in', true);
        } else {
            if (last_sso_logged_in) {
                if (last_collector_token !== undefined) {
                    localStorage.removeItem('last_collector_token');
                    last_collector_token = undefined;
                }
                localStorage.removeItem('last_sso_logged_in');
            }
        }

        if (last_collector_token !== undefined) {
            collector_token = last_collector_token;
        }

        store.set('platform', teeClientPlatform);

        TeeLogger.log('Call Init');

        const payload = {
            method: 'init',
            collector_token: collector_token,
            api_token: store.get('api_token'),
            remote_id: store.get('remote_id'),
            email: store.get('email'),
            anonymous_id: store.get('anonymous_id'),
            username: store.get('username'),
            update_id: store.get('update_id'),
            client: tee_client,
            stats: localStorage.getItem('tee_stats'),
        }

        const portal = Object.fromEntries(store.get('default_params') ?? []).portal ?? null
        if (portal) {
            payload.portal = portal;
        }

        TeeConnect.send(payload);

        TeeTracker.mount();
    };
    this.closing = function() {
        TeeFrontend.me.removeTeeIcon();
    };
    this.registerDevice = (deviceToken, pushAlias = '') => {
        TeeConnect.send({
            method: 'registerDevice',
            collector_token: store.get('collector_token'),
            device_token: deviceToken,
            push_alias: pushAlias,
            platform: store.get('platform')
        });
    };
    this.messageReceiver = (message) => {
        let msgFilter = ['pong'];
        if (message.caller) {
            TeeLogger.msg(message.caller);
        } else {
            if (message.msg) {
                if (!msgFilter.includes(message.msg)) {
                    TeeLogger.msg(message.msg);
                }
            }
        }

        switch (message.caller) {
            case 'init':
                TeeLogger.msg(message.msg);
                if (message.data && message.data.collector_token) {
                    store.set('initialized', true);
                    store.set('frontend_url', message.data.frontend_url);
                    store.set('frontend_entrypoint', message.data.frontend_entrypoint);
                    store.set('collector_token', message.data.collector_token);
                    store.set('uri_scheme', message.data.uri_scheme);

                    if (message.data.collector_token !== localStorage.getItem('last_collector_token')) {
                        TeeTracker.mount();
                    }
                    TeeConnect.send({
                        method: 'me'
                    });
                } else {
                    TeeLogger.error(`Received response ${message.data.msg}`);
                }
            break;
            case 'me':
                localStorage.setItem('last_collector_token', message.data.collector_token);
                localStorage.setItem('tee_points_available', message.data.points_available);
                message.data.currency = message.data.currency || { visible: false, meta: [], basename: '' };
                store.set('me', message.data);
                store.set('initialized', true);
                store.set('points', message.data.points_available);
                store.set('oldpoints', Math.max(message.data.points_available - 150, 0));
                store.set('basename', message.data.currency.basename);
                store.set('currencyClassName', 'me ' + getMeta(message.data.currency.meta, 'className'));
                TeeLogger.log('Loaded Collector: ', message.data.collector_token);

                let teeparam = this.getUrlParameter('tee');
                if (teeparam) {
                    store.set('entryOverride', teeparam);
                }

                let optionalParams = [];
                allowedParams.forEach(param => {
                    const value = this.getUrlParameter(param)
                    if (value) {
                        optionalParams.push({param, value})
                    }
                });
                store.set('optionalParameter', optionalParams);

                window.dispatchEvent(new CustomEvent('tee_initialized', {detail: message.data}));

                this.me.init();
                TeeElements.init();
            break;
            case 'log':
                if (message.msg === 'points_change') {
                    this.points.setPoints(message.data.points_after);
                }
            break;
        }

        if (!message.caller) {
            switch (message.msg) {
                case 'points_change':
                    this.points.setPoints(message.data.points_after);
                break;
                case 'popup_notify':
                    this.me.showPopUp(message);
                break;
                case 'collector_optout':
                    this.destroy();
                break;
            }
        }
    };
    this.windowMessageEvent = (e) => {
        //Check if the Origin of the Close Event is tee
        let xorigin = e.origin.split(".");
        xorigin.reverse();
        let origin = xorigin[1] + "." + xorigin[0];

        let xdomain = String(store.get('frontend_url')).split(".");
        xdomain.reverse();
        let domain = "";
        if (xdomain.length > 1) {
            domain = xdomain[1] + "." + xdomain[0];
        }

        if (origin != domain) { return; }
        let uri_scheme = store.get('uri_scheme') + "://";
        if (String(e.data).startsWith(uri_scheme)) {
            let data = e.data;
            data = data.substring(uri_scheme.length);
            data = data.split("$");
            switch(data[0])
            {
                case "vplus-registration-form":
                    const dataToPassRegister = this.getDataForLogin(data);
                    dataToPassRegister.message = 'open_register_vplus_form'
                    window.postMessage(dataToPassRegister, dataToPassRegister.origin)
                break;
                case "registration-form":
                    const dataToPass = this.getDataForLogin(data);
                    dataToPass.message = 'open_login_form'
                    window.postMessage(dataToPass, dataToPass.origin)
                break;
                case "open-link":
                    TeeFrontend.me.closeTEE();
                    window.location = data[1];
                break;
                case "open-link-blank":
                    window.open(data[1], '_blank')
                break;
                case "deeplink":
                    const closeEvent = JSON.parse(JSON.stringify(e))
                    closeEvent.data = 'tee_closePopup'
                    closeEvent.origin = e.origin
                    TeeFrontend.me.popupClose(closeEvent)

                    if (data[1].includes('special-deeplink')) {
                        const jsonData = JSON.parse(data[1].split('special-deeplink')[1])
                        window.postMessage({message: 'special-deeplink', data: jsonData }, window.location.origin)
                        TeeFrontend.me.closeTEE()
                    } else {
                        TeeFrontend.me.openTee(undefined, data[1]);
                    }
                break;
                case "tee_close":
                    TeeFrontend.me.closeTEE();
                break;
                case "tee_lockpoints":
                    TeeFrontend.points.lockPoints();
                break;
                case "tee_unlockpoints":
                    TeeFrontend.points.unlockPoints();
                break;
            }
        }
    };

    this.getDataForLogin = (data) => {
        const url = data[1]
        const dataToPass = { origin: window.location.origin }
        if (url !== '' && url !== null) {
            this.appendDeeplink(url)
            dataToPass.url = url
        } else {
            dataToPass.url = ''
        }

        TeeFrontend.me.closeTEE(); //close Tee
        TeeFrontend.me.popupClose();

        return dataToPass;
    }

    this.getUrlParameter = (name) => {
      name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
      const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
      const results = regex.exec(window.location.search);
      return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    this.appendDeeplink = (url) => {
        const urlObject = new URL(window.location)
        urlObject.searchParams.append('tee', url);
        window.history.replaceState( {} , '', urlObject );
    }
};

var TeeFrontend = new TEE();
window.TeeFrontend = TeeFrontend;
window._rm_store = store;
TeeFrontend.mount();
