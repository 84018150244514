import store from './Store.js';
import TeeLogger from './Logger.js';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import {generateUrl} from "./Helper";

function Me(element, TeeConnect) {
    this.pointsEl = null;
    this.pointsUpdateEl = null;
    this.iconBound = false;
    this.teeMain = element;
    this.teeframe = null;
    this.teePopup = null;
    this.teeBg = null;
    this.teeOffsetEl = null;
    this.teeOffsetElMobile = null;
    this.popupframe = null;
    this.platformsize = "";
    this.meEl = null;
    this.url = null;

    this.mount = (invisible = false) => {
        if (store.get('initialized')) {
            let mainEl = element;
            this.meEl = document.createElement('div');
            this.meEl.classList.add('me');
            mainEl.appendChild(this.meEl);

            this.teeOffsetEl = document.querySelector('.tee-offsetEl');
            this.teeOffsetElMobile = document.querySelector('.tee-offsetEl-mobile');

            TeeLogger.log('Loaded Me');

            if (store.get('me').currency.visible) {
                TeeLogger.log('Mounting Me');

                let clientCSS = store.get('me').currency.client_css;
                if (clientCSS) {
                    let css = document.createElement('style');

                    document.getElementsByTagName('head')[0].appendChild(css);
                    css.innerHTML = clientCSS;
                }

                if (invisible) {
                    return this.bindTeeIframe(store.get('platform'));
                }

                let currencyEl = document.createElement('div');
                store.get('currencyClassName').split(" ").forEach(mel => {
                    if (mel != "") {
                        currencyEl.classList.add(mel);
                    }
                });
                this.meEl.appendChild(currencyEl);

                let currencyImg = document.createElement('img');
                const currency = store.get('me').currency;
                if (currency.icons.navigation) {
                    currencyImg.src = currency.icons.navigation.url;
                } else {
                    currencyImg.src = currency.icons.default.url;
                }

                currencyEl.appendChild(currencyImg);

                this.pointsEl = document.createElement('div');
                this.pointsEl.classList.add('points');
                this.pointsEl.innerHTML = store.get('me').points_available;
                currencyEl.appendChild(this.pointsEl);

                this.pointsUpdateEl = document.createElement('div');
                this.pointsUpdateEl.classList.add('points');
                this.pointsUpdateEl.classList.add('points-update');
                currencyEl.appendChild(this.pointsUpdateEl);

                store.observe('me', this.updatePoints);

                this.bindTeeIframe(store.get('platform'));
            } else {
                this.removeTeeIcon();
            }
        }
    };
    this.updatePoints = (val, oldVal) => {
        if (!oldVal) {
            return;
        }

        const oldpoints = oldVal.points_available;

        const points = val.points_available
        const pointsChanged = points - oldpoints

        if (pointsChanged === 0) {
            return;
        }

        if (pointsChanged > 0) {
            this.pointsUpdateEl.innerHTML = '+' + pointsChanged
        } else {
            this.pointsUpdateEl.innerHTML = pointsChanged
            this.pointsUpdateEl.classList.add('points-decrease');
        }

        window.setTimeout(() => {
            this.pointsEl.innerHTML = points
        }, 1000)

        this.pointsUpdateEl.classList.remove('tee-animate-points-update')
        this.pointsEl.classList.remove('tee-animate-points')
        this.meEl.classList.remove('tee-animate-me')

        void this.pointsUpdateEl.offsetWidth;
        void this.pointsEl.offsetWidth;
        void this.meEl.offsetWidth;

        this.pointsUpdateEl.classList.add('tee-animate-points-update')
        this.pointsEl.classList.add('tee-animate-points')
        this.meEl.classList.add('tee-animate-me')

        window.setTimeout(() => {
            this.pointsUpdateEl.classList.remove('tee-animate-points-update')
            this.pointsEl.classList.remove('tee-animate-points')
            this.meEl.classList.remove('tee-animate-me')
            this.pointsUpdateEl.classList.remove('points-decrease');
        }, 4000)
    };
    this.bindTeeIframe = function(platform) {
        let teemain = element;
        let ddiv = document.createElement('div');
        let teeframe = document.createElement('iframe');
        let savedOffset = 0;
        let savedHeight = 0;
        this.teeBg = document.createElement('div');

        this.teeBg.classList.add('tee-bg');
        this.teeBg.style.display = 'none';

        teemain.append(this.teeBg);

        teeframe.style.width = '100%';
        teeframe.style.height = '100%';
        teeframe.style.border = 'none';
        teeframe.allow = 'microphone; camera';

        ddiv.append(teeframe);
        ddiv.style.position = 'absolute';
        teemain.append(ddiv);

        const loadedEvent = new CustomEvent('tee', {
            detail: {
                target: 'me',
                event: 'loaded',
                me: this
            },
        })

        const ro = new ResizeObserver((entries) => {
            for (const entry of entries) {
                const {width} = entry.contentRect;

                if (width >= 800) {
                    if (this.platformsize != "desktop" ) {
                        this.platformsize = "desktop";
                        ddiv.style.width = "400px";
                        ddiv.style.height = "650px";
                        ddiv.style.right = "0";
                        ddiv.style.left = "auto";

                        ddiv.style.display = 'none';

                        window.dispatchEvent(loadedEvent)
                    }

                    const parentOffset = teemain.parentElement.offsetHeight + teemain.parentElement.offsetTop;
                    if (parentOffset !== savedOffset) {
                        ddiv.style.top = `${parentOffset}px`;
                        this.teeBg.style.top = `${parentOffset}px`;
                        savedOffset = parentOffset
                    }

                    if (window.innerHeight < (650 + parentOffset)) {
                        const ddHeight = window.innerHeight - parentOffset

                        if (ddHeight !== savedHeight) {
                            ddiv.style.height = `${ddHeight}px`;
                            savedHeight = ddHeight;
                        }
                    }
                } else {
                    if (this.platformsize !== "mobileweb" ) {
                        this.platformsize = "mobileweb";
                        ddiv.style.width = "100%";
                        ddiv.style.height = "100vh";
                        ddiv.style.top = `${teemain.parentElement.offsetHeight}px`;
                        this.teeBg.style.top = `${teemain.parentElement.offsetHeight}px`;
                        const parentOffset = teemain.parentElement.offsetHeight + teemain.parentElement.offsetTop;
                        ddiv.style.height = `${window.innerHeight - parentOffset}px`;
                        ddiv.style.left = "0";
                        ddiv.style.display = 'none';

                        window.dispatchEvent(loadedEvent)
                    }
                }
            }
        });
        ro.observe(document.body);

        this.teeframe = teeframe;

        let pointEl = element.querySelector(".me");
        pointEl.addEventListener("click", (e) => {
            this.openTEE();
        });
        pointEl.style.cursor = "pointer";

        window.addEventListener("click", () => {
          this.closeTEE()
        })
        this.teeframe.addEventListener("click", (e) => e.stopPropagation())
        this.meEl.addEventListener("click", (e) => e.stopPropagation())

        this.iconBound = true;
        window.addEventListener('message', TeeFrontend.windowMessageEvent, false);
    };
    this.openTEE = (params = "", entryOverride = "") => {
        if (store.get('frontend_url') === null || store.get('frontend_entrypoint') === null) {
            return;
        }

        if (this.teeframe.parentElement.style.display === 'block' && (entryOverride === '' || entryOverride !== this.teeframe.src)) {
            this.closeTEE();
        } else {
          const url = generateUrl(entryOverride, params)

          if (this.teeframe.parentElement.style.display === 'none' && url === this.url) {
            this.teeframe.parentElement.style.display = "block";
            this.teeframe.contentWindow.postMessage('tee-reset-to-home', store.get('frontend_url'));
          } else {
            let parentElement = this.platformsize === 'mobileweb' ? this.teeOffsetElMobile : this.teeOffsetEl;
            parentElement = parentElement !== null ? parentElement : this.teeMain.parentElement;

            const offset = parentElement.offsetHeight + parentElement.offsetTop;

            let elHeight = window.innerHeight - offset;
            elHeight = elHeight < 650 ? elHeight : 650;
            this.teeframe.parentElement.style.height = `${elHeight}px`;

            this.teeframe.parentElement.style.top = `${offset}px`;
            this.teeBg.style.top = `${offset}px`;

            window.dispatchEvent(new CustomEvent('tee_opening', {'detail': {'platform': this.platformsize}}));

            this.url = url
            this.teeframe.src = url;

            this.teeframe.focus();
            this.teeframe.onload = () => {
              this.teeframe.parentElement.style.display = "block";
            };
          }

          this.teeBg.style.display = 'block';

          this.lockBody();
        }
    };
    this.closeTEE = () => {
        this.teeframe.parentElement.style.display = "none";
        window.dispatchEvent(new CustomEvent('tee_closing', {'detail': {'platform': this.platformsize}}));
        this.teeBg.style.display = 'none';
        this.unlockBody();
    };
    this.removeTeeIcon = function() {
        let teemain = element;
        if (this.teeframe) {
            this.teeframe.parentElement.style.display = "none";
            this.teeframe.src = "";
            teemain.innerHTML = "";
        }
    };

    this.scrollTo = () => {
        const offsetTop = this.teeMain.getBoundingClientRect().top - 250;

        window.scrollTo(0, offsetTop);
    }

    this.lockBody = () => {
      if (store.get('bodyLockDisabled')) {
        return;
      }
      // Should consider the gap for the scrollbar.
      disableBodyScroll(
        this.teeframe.parentElement,
        {
          reserveScrollBarGap: true,
        }
      );
    };

    this.unlockBody = () => {
      if (store.get('bodyLockDisabled')) {
        return;
      }

      enableBodyScroll(this.teeframe.parentElement);
    };
};

export { Me };
