module.exports = {
    version: '1.4.1',
    debug: $APP_DEBUG,
    ws: {
        "service": '$TEE_FRONTEND_WEBSOCKET_URL'
    },
    serviceURI: "$TEE_ASSETS_URL",
    css: [
        '$TEE_ASSETS_URL/css/tee.css'
    ],
    zindex: 99
}
