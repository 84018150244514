import store from './Store.js';
import TeeLogger from './Logger.js';
import TimeMe from 'timeme.js';
import Settings from './Settings.js';

function Tracker(TeeConnect) {
    this.state = {};
    this.boundSteps = [];
    this.timerInitialized = {};
    this.ovserverObserving = false;
    this.waitingBinds = [];
    this.triggered = [];

    this.mount = () => {
        window.addEventListener('popstate', this.callForAction);
        window.addEventListener('newPage', this.callForAction);

        TeeConnect.addMessageReceiver(this.handleResponse);

        this.callForAction();
    };
    this.callForAction = () => {
        this.unbindSteps();

        TeeConnect.send({
            method: 'challenges',
            for: window.location.href
        })
    };
    this.handleResponse = (message) => {
        if (message.caller === 'challenges') {
            if (message.data.steps) {
                TeeLogger.log(message.data.steps.length + ' Challenges recieved!');

                this.resetTriggered()

                message.data.steps.forEach((step) => this.bindStep(step));
            }
        }
    };
    this.bindStep = (step) => {
        TeeLogger.log('Binding Step', step);
        if (step.deferred) {
            this.bindDeferredStep(step);
        } else if (!step.bind_to || this.requirementsMet(step)) {
            let r = new RegExp(step.matched_pattern);
            const dataPayload = {
              ...step.matched_data,
            }
            const portal = Object.fromEntries(store.get('default_params') ?? []).portal ?? null;
            if (portal) {
              dataPayload.portal = portal;
            }

            TeeConnect.send({
                method: 'log',
                data: dataPayload,
                step: step.token
            });
        } else {
            this.bindStepForCurrentView(step);
        }
    };
    this.bindDeferredStep = (step) => {
        if (this.timerInitialized[step.token]) {
            return;
        }

        TimeMe.initialize({
            currentPageName: step.token,
            idleTimeoutInSeconds: 5
        });

        if (Settings.debug) {
            setInterval(function() {
                    store.set('timeOnPage', TimeMe.getTimeOnPageInSeconds(step.token).toFixed(2));
            }, 25);
        }

        function ping(a) {
            a = a || {};
            TeeConnect.send(Object.assign({
                method: 'ping',
                step: step.token
            }, a));
        }

        TimeMe.callWhenUserReturns(ping);
        TimeMe.callWhenUserLeaves(ping);
        ping();

        this.timerInitialized[step.token] = true;
    };
    this.bindStepForCurrentView = (step) => {

        const defaultStepEventTarget = Settings.defaultStepEventTarget ?? window;

        const trigger = step.bind_to.triggered_by;
        if (!trigger) {
            return;
        }

        const bind_to = trigger.obj ?
            Array.from(document.querySelectorAll(trigger.obj)) :
            [defaultStepEventTarget];

        if (bind_to.length > 0) {
            bind_to.forEach((o) => {
                const event = trigger.event;
                const mapping = this.handlers[event];
                if (!mapping) return;

                const dataPayload = {
                  ...step.matched_data,
                  ...trigger.data,
                }
                const portal = Object.fromEntries(store.get('default_params') ?? []).portal ?? null;
                if (portal) {
                  dataPayload.portal = portal;
                }

                if (o !== defaultStepEventTarget) {
                    if (step.featured) {
                        o.dataset.teeEventData = JSON.stringify({
                            method: 'log',
                            step: step.token,
                            featured: true,
                            featured_obj: trigger.featured,
                            data: dataPayload,
                        });
                    } else {
                        o.dataset.teeEventData = JSON.stringify({
                            method: 'log',
                            step: step.token,
                            featured: false,
                            data: dataPayload,
                        });
                    }

                    let f = mapping;
                    if (event === 'scroll') {
                        f = debounce(mapping);
                    }
                    o.addEventListener(event, f);
                    this.boundSteps.push({o, event, f})

                } else {
                    const context = {
                        method: 'log',
                        step: step.token,
                        data: dataPayload,
                    };

                    const events = {
                        'scroll': () => {
                            const childF = mapping.bind(context);
                            const f = function scroll(){
                                childF(o, context);
                            }
                            o.addEventListener(event, f);

                            return {f, event_name: event}
                        },
                        'event': () => {
                            const event_name = "tee_event_" + trigger.event_name;
                            const childF = mapping.bind(context);
                            const f = function event(){
                                childF(o, context);
                            }

                            o.addEventListener(event_name, f);

                            return {f, event_name}
                        },
                        'trigger_event': () => {
                            const event_name = "tee_event_" + trigger.event_name;

                            const event = new CustomEvent(event_name, {detail: { target: 'tee', tee: step.specialData}})
                            o.dispatchEvent(event)

                            const childF = mapping.bind(context);
                            const f = function trigger_event(){
                                childF(o, context);
                            }
                            o.addEventListener(event_name + '_trigger', f);

                            // execute 60 times to catch cases where tee was too fast and handler was not yet registered
                            let counter = 0
                            const timeout = window.setInterval(() => {
                                if (counter === 60) {
                                    return window.clearInterval(timeout)
                                }
                                counter++
                                o.dispatchEvent(event)
                            }, 250)

                            return {f, event_name}
                        }
                    }

                    const {f, event_name} = events[event]();

                    this.boundSteps.push({o, event: event_name, f, context})

                }
            });

            if (this.waitingBinds.includes(step)) {
                delete this.waitingBinds[this.waitingBinds.indexOf(step)];
            }
        } else {
            //cant find object in current dom, starting observer
            if (this.waitingBinds.includes(step)) {
                this.waitingBinds.push(step);
            }

            if (!this.observerObserving) {
                // configuration of the observer:
                const config = {
                    attributes: true,
                    characterData: true,
                    childList: true,
                    subtree: true,
                    attributeOldValue: true,
                    characterDataOldValue: true,
                };

                const observer = new MutationObserver(this.observerDetectedElement);
                observer.observe(document.documentElement, config);
                this.observerObserving = true;
            }

        }
    };

    this.observerDetectedElement = (mutations) => {
        const self = TeeFrontend.TeeTracker; //because this is not the same inside the observer
        if (self.waitingBinds) {
            self.waitingBinds.forEach(function(t) {
                self.bindStepForCurrentView(t);
            });
        }

    };
    this.handlers = {
        click: (that) => {
            try {
                let o = JSON.parse(that.currentTarget.dataset.teeEventData);
                TeeConnect.send({
                    method: o.method,
                    data: Object.assign({}, o.data),
                    step: o.step
                });
            } catch (e) {}
        },
        scroll: (that, context) => {
            if (context.data.threshold <= window.scrollY) {
              if (this.checkIfTriggered(context.step)) return;
                TeeConnect.send({
                    method: context.method,
                    data: Object.assign({}, context.data),
                    step: context.step
                });
            }
        },
        submit: (that) => {
            try {
                let o = JSON.parse(that.currentTarget.dataset.teeEventData);
                TeeConnect.send({
                    method: o.method,
                    data: Object.assign({}, o.data),
                    step: o.step
                });
            } catch (e) {}
        },
        event: (that, context) => {
          if (this.checkIfTriggered(context.step)) return;

            try {
                TeeConnect.send({
                    method: context.method,
                    data: Object.assign({}, context.data),
                    step: context.step
                });
            } catch (e) {}
        },
        trigger_event: (that, context) => {
          if (this.checkIfTriggered(context.step)) return;

          try {
              TeeConnect.send({
                  method: context.method,
                  data: Object.assign({}, context.data),
                  step: context.step
              });
          } catch (e) {}
        },
    };
    this.unbindSteps = () => {
        this.boundSteps.forEach((bound) => {
            bound.o.removeEventListener(bound.event, bound.f);
        });
        this.boundSteps = [];
    };
    this.requirementsMet = (step) => {
        // check if the assertion (if any) is met
        if (step.bind_to.triggered_by || step.bind_to.deferred) {
            return false;
        }
        if (step.bind_to.assert_class) {
            if (document.querySelector(step.bind_to.assert_class) === null) {
                return false;
            }
        }
        return true;
    };
    this.checkIfTriggered = (step) => {
      if (this.triggered[step]) {
        return true;
      }
      this.triggered[step] = true;

      return false;
    }
    this.resetTriggered = () => {
        this.triggered = [];
    }
}

function debounce (func, wait = 20, immediate = true) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

export { Tracker };
